import { useMemo, useState } from "react"
import { NavLink } from "react-router-dom"

import { SorterResult } from "antd/es/table/interface"

import { DeleteOutlined, CaretRightOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { useStore } from "@nanostores/react"
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  TableProps,
  Typography,
} from "antd"
import { useAllProducts } from "api/endpoints/products/products"
import { ProductDto } from "api/model"
import { useSyncAssortments } from "features/recipesCalculator/lib/useSyncAssortments"
import { recipesCalculator } from "features/recipesCalculator/model"
import { NormalizationMethod, RecipeAssortment } from "features/recipesCalculator/types"
import hash from "object-hash"
import { clientModuleRoutes } from "shared/constants/routes"

import { createTableColumns } from "./artifacts/createTableColumns"

const { Item } = Form

type TFormValuesAdd = {
  productIdAdd: number
  normalizationMethodAdd: NormalizationMethod
  currentWeightAdd: number
}
type TFormValuesEdit = {
  productIdEdit: number
  normalizationMethodEdit: NormalizationMethod
  currentWeightEdit: number
}

const normalizationMethodOptions = [
  { value: "stream", label: "В потоке" },
  { value: "volume", label: "Объемная" },
]

export const RecipesAssortment = () => {
  const assortments = useStore(recipesCalculator.$assortments)

  const { data: products } = useAllProducts(
    {},
    {
      query: { select: (res) => res.result ?? [] },
    },
  )

  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [assortmentEdit, setAssortmentEdit] = useState<RecipeAssortment>()
  const [assortmentForDelete, setAssortmentForDelete] = useState<RecipeAssortment>()
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [changeConfirmModal, setChangeConfirmModal] = useState<{
    desc: string
    confirm: () => void
  }>()

  const [isOpenDeleteAllConfirmModal, setIsOpenDeleteAllConfirmModal] = useState(false)

  const [formAdd] = Form.useForm()
  const [formEdit] = Form.useForm()

  const [sortedInfo, setSortedInfo] = useState<SorterResult<RecipeAssortment>>({})

  const isGotoCalculatorActive = useMemo(() => {
    return assortments?.length > 0 && assortments.every((el) => Boolean(el.selectedRecipe))
  }, [assortments])

  const onEdit = (record: RecipeAssortment) => {
    setAssortmentEdit(record)
    formEdit.setFieldValue("productIdEdit", record.product.id)
    formEdit.setFieldValue("currentWeightEdit", record.currentWeight)
    formEdit.setFieldValue("normalizationMethodEdit", record.normalizationMethod)
    setIsOpenEdit(true)
  }

  const onDelete = (record: RecipeAssortment) => {
    setAssortmentForDelete(record)
    setIsOpenDeleteConfirmModal(true)
  }

  const productsObject = useMemo(() => {
    if (!products) return {}
    const _productsObject: { [key: string]: ProductDto } = {}
    products.forEach((product) => {
      _productsObject[product.id] = product
    })
    return _productsObject
  }, [products])

  const deleteAssortment = () => {
    if (!assortmentForDelete) return

    recipesCalculator.setAssortments([
      ...assortments.filter((obj) => hash(obj) !== hash(assortmentForDelete)),
    ])
    setIsOpenDeleteConfirmModal(false)
  }

  const addAssortment = (values: TFormValuesAdd) => {
    const normalizationMethod = values.normalizationMethodAdd || "stream"
    let isEdited
    const assortmentsNew = assortments.map((element) => {
      if (
        element.product.id !== values.productIdAdd ||
        element.normalizationMethod !== normalizationMethod
      ) {
        return element
      }
      isEdited = true
      return {
        ...element,
        currentWeight: values.currentWeightAdd + element.currentWeight,
        normalizationMethod,
      }
    })
    if (isEdited) {
      setChangeConfirmModal({
        desc:
          "Данный продукт уже добавлен. Хотите увеличить его количество на " +
          values.currentWeightAdd +
          " кг?",
        confirm: () => {
          recipesCalculator.setAssortments(assortmentsNew)
          formAdd.resetFields()
        },
      })
    } else {
      recipesCalculator.setAssortments([
        {
          currentWeight: values.currentWeightAdd,
          normalizationMethod,
          product: productsObject[values.productIdAdd],
          selectedRecipe: productsObject[values.productIdAdd].recipe,
          type: "recipe",
        },
        ...assortmentsNew,
      ])
      formAdd.resetFields()
    }
  }

  const editAssortment = (values: TFormValuesEdit) => {
    const assortmentsUpdated = assortments.map((element) => {
      if (
        element.product.id === assortmentEdit?.product.id &&
        element.normalizationMethod === assortmentEdit?.normalizationMethod
      ) {
        return {
          product: productsObject[values.productIdEdit],
          currentWeight: values.currentWeightEdit,
          normalizationMethod: values.normalizationMethodEdit,
          selectedRecipe: element.selectedRecipe,
        }
      }
      return element
    })

    let isExistingItem = false

    const map = new Map()
    for (const item of assortmentsUpdated) {
      const key = `${item.product.id}-${item.normalizationMethod}`
      const existingItem = map.get(key)

      if (existingItem) {
        isExistingItem = true
        existingItem.currentWeight += item.currentWeight
      } else {
        map.set(key, { ...item })
      }
    }
    const assortmentsNew = Array.from(map.values())
    if (isExistingItem) {
      setChangeConfirmModal({
        desc:
          "Данный продукт уже добавлен. Хотите увеличить его количество на " +
          values.currentWeightEdit +
          " кг?",
        confirm: () => {
          recipesCalculator.setAssortments(assortmentsNew)
          setIsOpenEdit(false)
          setChangeConfirmModal(undefined)
          formEdit.resetFields()
        },
      })
    } else {
      recipesCalculator.setAssortments(assortmentsNew)
      setIsOpenEdit(false)
      formEdit.resetFields()
    }
  }

  const validatePositiveNumber = (_: unknown, value: number) => {
    if (value === undefined || value <= 0) {
      return Promise.reject("Число должно быть положительным")
    }
    return Promise.resolve()
  }

  const productsOptions = useMemo(() => {
    if (!products) return []
    return products.map((product) => {
      return { value: product.id, label: `${product.name} (${product.fatPercentage}%)` }
    })
  }, [products])

  const handleChange: TableProps<RecipeAssortment>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<RecipeAssortment>)
  }

  useSyncAssortments()

  return (
    <Space direction='vertical'>
      <Space>
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          Ассортимент продуктов
        </Typography.Title>
      </Space>
      <Space direction='vertical' style={{ marginTop: 30 }}>
        <Button
          onClick={() => setIsOpenDeleteAllConfirmModal(true)}
          type='primary'
          danger
          disabled={!assortments.length}
          icon={<DeleteOutlined />}
        >
          Удалить все
        </Button>
        <Table
          columns={createTableColumns({ sortedInfo, onDelete, onEdit })}
          dataSource={assortments}
          onChange={handleChange}
          pagination={false}
          locale={{ emptyText: "Нет данных" }}
        />
      </Space>

      <Form
        layout={"inline"}
        name='assortment'
        form={formAdd}
        initialValues={{
          normalizationMethodAdd: normalizationMethodOptions[0].value,
        }}
        style={{ marginTop: 20 }}
        onFinish={addAssortment}
      >
        <Item
          name='productIdAdd'
          label='Продукт'
          rules={[{ required: true, message: "Заполните это поле" }]}
        >
          <Select
            style={{ minWidth: 400 }}
            options={productsOptions}
            placeholder='Выберите продукт'
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            showSearch
          />
        </Item>
        <Item name='normalizationMethodAdd' label='Способ нормализации'>
          <Select
            defaultActiveFirstOption
            style={{ minWidth: 200 }}
            options={normalizationMethodOptions}
            filterOption={(input, option) => (option?.label ?? "").includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            showSearch
          />
        </Item>
        <Item
          name='currentWeightAdd'
          label='Масса, кг'
          rules={[{ validator: validatePositiveNumber }]}
        >
          <InputNumber
            placeholder='Масса, кг'
            required
            decimalSeparator=','
            precision={2}
            min={0}
          />
        </Item>
        <Button
          style={{ marginTop: 20 }}
          htmlType='submit'
          type='primary'
          icon={<PlusCircleOutlined />}
        >
          Добавить
        </Button>
      </Form>
      <Space style={{ marginTop: 30 }}>
        <NavLink to={clientModuleRoutes.recipes.calculator.root}>
          <Button disabled={!isGotoCalculatorActive} type='primary' icon={<CaretRightOutlined />}>
            Перейти к расчёту
          </Button>
        </NavLink>
      </Space>
      <Modal
        title='Редактирование ассортимента'
        centered
        open={isOpenEdit}
        onOk={() => setIsOpenEdit(false)}
        onCancel={() => setIsOpenEdit(false)}
        footer={false}
      >
        <Form
          name='assortmentEdit'
          form={formEdit}
          style={{ marginTop: 20 }}
          labelCol={{ span: 9 }}
          onFinish={editAssortment}
        >
          <Item
            name='productIdEdit'
            label='Продукт'
            rules={[{ required: true, message: "Заполните это поле" }]}
          >
            <Select
              style={{ minWidth: 200 }}
              options={productsOptions}
              placeholder='Выберите продукт'
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
            />
          </Item>
          <Item
            name='normalizationMethodEdit'
            label='Способ нормализации'
            // rules={[{ required: true, message: "Заполните это поле" }]}
          >
            <Select
              defaultValue='stream'
              style={{ minWidth: 200 }}
              options={normalizationMethodOptions}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
            />
          </Item>
          <Item
            name='currentWeightEdit'
            label='Масса'
            rules={[{ validator: validatePositiveNumber }]}
          >
            <InputNumber placeholder='Масса' required decimalSeparator=',' precision={2} />
          </Item>
          <Space direction={"horizontal"}>
            <Button type='primary' htmlType='submit'>
              Сохранить
            </Button>
          </Space>
        </Form>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={isOpenDeleteConfirmModal}
        onOk={() => setIsOpenDeleteConfirmModal(false)}
        onCancel={() => setIsOpenDeleteConfirmModal(false)}
        footer={false}
      >
        <Space direction='vertical'>
          <Typography.Title level={5} style={{ marginBottom: 30 }}>
            Вы действительно ходите удалить ассортимент?
          </Typography.Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => deleteAssortment()}
              type='primary'
              htmlType='submit'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить
            </Button>
            <Button onClick={() => setIsOpenDeleteConfirmModal(false)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={isOpenDeleteAllConfirmModal}
        onOk={() => setIsOpenDeleteAllConfirmModal(false)}
        onCancel={() => setIsOpenDeleteAllConfirmModal(false)}
        footer={false}
      >
        <Space direction='vertical'>
          <Typography.Title level={5} style={{ marginBottom: 30 }}>
            Вы действительно ходите удалить весь ассортимент?
          </Typography.Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => {
                recipesCalculator.clearAssortments()
                setIsOpenDeleteAllConfirmModal(false)
              }}
              type='primary'
              htmlType='submit'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить всё
            </Button>
            <Button onClick={() => setIsOpenDeleteAllConfirmModal(false)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={!!changeConfirmModal}
        onOk={() => setChangeConfirmModal(undefined)}
        onCancel={() => setChangeConfirmModal(undefined)}
        footer={false}
      >
        <Space direction='vertical'>
          <Typography.Title level={5} style={{ marginBottom: 30 }}>
            {changeConfirmModal?.desc}
          </Typography.Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => {
                changeConfirmModal?.confirm()
                setChangeConfirmModal(undefined)
              }}
              type='primary'
              htmlType='submit'
            >
              Подтвердить
            </Button>
            <Button onClick={() => setChangeConfirmModal(undefined)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
    </Space>
  )
}
