import { useMemo, useState } from "react"

import Search from "antd/es/input/Search"
import { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { Modal, Select, TableProps } from "antd"
import {
  useAllBasicMaterials,
  useCreateCopyBasicMaterial,
} from "api/endpoints/materials-basic/materials-basic"
import { BasicMaterialDto, BasicMaterialForm } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseJournal } from "shared/BaseJournal"
import { measureLocales, measureOptions } from "shared/constants/materials/measureOptions"
import { defaultSortedInfo } from "shared/constants/sort"
import { exportTable } from "shared/utils/exportTable"
import { selectListResultOptions } from "shared/utils/selectListResult"

import { createTableColumns } from "./artifacts/createTableColumns"
import { BasicMaterialsModal } from "./components/BasicMaterialsModal"

const defaultInitialData = {
  measure: "KILOGRAM",
  article: "О",
  name: "",
}

export const BasicMaterials = () => {
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [selectValues, setSelectValues] = useState<string[]>([])
  const [sortedInfo, setSortedInfo] = useState<SorterResult<BasicMaterialDto>>(defaultSortedInfo)
  const [initialData, setInitialData] =
    useState<Partial<BasicMaterialForm & { id: number }>>(defaultInitialData)

  const {
    data: basicMaterials,
    isFetching: isMaterialsFetching,
    queryKey: basicMaterialsQueryKey,
  } = useAllBasicMaterials({}, { query: { select: selectListResultOptions } })

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const onSelect = (value: string[]) => {
    setSelectValues(value)
  }

  const materialsData = useMemo(() => {
    if (!basicMaterials) return []
    const data = basicMaterials.map((item, index) => ({ ...item, mockId: index + 1 }))
    return data
      .filter((material) => {
        const isValuesFromSelectValues =
          selectValues.length > 0 ? selectValues.includes(material.measure) : true
        return searchValue && isValuesFromSelectValues
          ? material.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1
          : isValuesFromSelectValues
      })
      .sort()
  }, [basicMaterials, searchValue, selectValues])

  const handleChange: TableProps<BasicMaterialDto>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<BasicMaterialDto>)
  }

  const handleExport = () => {
    if (!basicMaterials) return

    const flattenData: Record<string, string | number | undefined>[] = basicMaterials.map(
      (item) => {
        return {
          id: item.id,
          Название: item.name,
          Артикул: item.article,
          "Единицы измерения": measureLocales[item.measure as keyof typeof measureLocales],
        }
      },
    )

    exportTable({ data: flattenData, fileName: "Основные материалы" })
  }

  const onEditClick = (record: BasicMaterialDto) => {
    setInitialData(record)
    setFormIsOpen(true)
  }

  const copyBasicMaterial = useCreateCopyBasicMaterial()

  const onCopyClick = async (id: number) => {
    Modal.confirm({
      title: "Вы точно хотите создать дубликат материала?",
      okText: "Да",
      okType: "primary",
      cancelText: "Нет",
      icon: null,
      onOk() {
        copyBasicMaterial
          .mutateAsync({ id })
          .then((res) => onEditClick(res.result))
          .then(() => queryClient.invalidateQueries({ queryKey: basicMaterialsQueryKey }))
          .catch(console.error)
      },
    })
  }

  const columns: ColumnsType<BasicMaterialDto> = useMemo(() => {
    return createTableColumns({
      sortedInfo,
      isLoading: isMaterialsFetching || copyBasicMaterial.isPending,
      onCopyClick,
      onEditClick,
    })
  }, [isMaterialsFetching, sortedInfo, copyBasicMaterial.isPending])

  return (
    <BaseJournal
      title='Основные материалы (ОМ)'
      onAddClick={() => setFormIsOpen(true)}
      onExportClick={handleExport}
      data={basicMaterials ?? []}
      table={{
        columns,
        dataSource: materialsData,
        loading: isMaterialsFetching || copyBasicMaterial.isPending,
        onChange: handleChange,
      }}
      filters={
        <>
          <Search onChange={onChange} placeholder='Поиск по названию' />
          <Select
            mode='multiple'
            allowClear
            style={{ minWidth: 200 }}
            onChange={onSelect}
            options={measureOptions}
            placeholder='Выберите единицу измерения'
          />
        </>
      }
      modals={
        <BasicMaterialsModal
          initialData={initialData}
          isOpen={formIsOpen}
          setIsOpen={setFormIsOpen}
          defaultData={defaultInitialData}
          setInitialData={setInitialData}
          id={initialData?.id}
        />
      }
    />
  )
}
