import { PackagingMaterialDto, RecipeDto } from "api/model"
import { measureLocales } from "shared/constants/materials/measureOptions"
import * as XLSX from "xlsx"

// export const createPackagingMaterialsTableData = (
//   recipes: RecipeDto[],
//   wb: XLSX.WorkBook,
//   sheetName: string,
// ) => {
//   const createWorkbook = (recipes: RecipeDto[]): XLSX.WorkBook => {
//     // Create a new workbook
//     const wb: XLSX.WorkBook = XLSX.utils.book_new()
//     // Create an array to hold the rows
//     const ws_data: any[][] = [
//       ["Номенклатурный номер", "Наименование материала", "Единица измерения", "Норма расхода"],
//     ]

//     let currentRowIndex = 1 // Start from 1 because of the header row

//     // Calculate max width for each column
//     const maxWidths = ws_data[0].map((header: string) => header.length)

//     recipes.forEach((recipe) => {
//       const packagingMaterials = (recipe.packagingMaterials || [])
//         .map((pm) => ({
//           ...pm.packagingMaterial,
//           lossPercentage: pm.expenditure?.lossPercentage,
//           measure: pm.packagingMaterial
//             ? measureLocales[pm.packagingMaterial.measure as keyof typeof measureLocales]
//             : "",
//         }))
//         .filter((pm): pm is PackagingMaterialDto & { lossPercentage: number } => pm !== undefined)

//       const recipeName = `Рецепт № ${recipe.name} (id: ${recipe.id})`

//       // Add the recipe name spanning all columns
//       ws_data.push([recipeName, null, null, null])
//       currentRowIndex++ // Increment the row index

//       // Check the width of the recipe name
//       maxWidths[0] = Math.max(maxWidths[0], recipeName.length)

//       // Add rows for each material
//       packagingMaterials.forEach((material) => {
//         const row = [material.article, material.name, material.measure, material.lossPercentage]
//         ws_data.push(row)
//         row.forEach((cell, index) => {
//           const cellLength = cell != null ? cell.toString().length : 0
//           maxWidths[index] = Math.max(maxWidths[index] || 0, cellLength)
//         })
//       })

//       currentRowIndex += packagingMaterials.length
//     })

//     // Create a worksheet
//     const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(ws_data)

//     // Initialize the merges array
//     ws["!merges"] = []

//     // Reset the row index for merging
//     currentRowIndex = 1

//     // Adding merge ranges after creating the worksheet
//     recipes.forEach((recipe) => {
//       const materialCount = recipe.packagingMaterials ? recipe.packagingMaterials.length : 0
//       ws["!merges"]?.push({ s: { r: currentRowIndex, c: 0 }, e: { r: currentRowIndex, c: 3 } })
//       currentRowIndex += 1 + materialCount // Skip over the recipe name row and its materials
//     })

//     // Adjust column widths
//     ws["!cols"] = maxWidths.map((width) => ({ wch: width + 2 }))

//     // Append the worksheet to the workbook
//     XLSX.utils.book_append_sheet(wb, ws, "Recipes")

//     return wb
//   }

//   const workbook = createWorkbook(recipes)

//   // Function to write the workbook to a file
//   function writeWorkbookToFile(workbook: XLSX.WorkBook, filename: string): void {
//     XLSX.writeFile(workbook, filename)
//   }

//   // Writing to a file (should be used in a Node.js environment)
//   writeWorkbookToFile(workbook, "recipes.xlsx")
// }

export const createPackagingMaterialsTableData = (
  recipes: RecipeDto[],
  wb: XLSX.WorkBook,
  sheetName: string,
) => {
  // Create an array to hold the rows
  const ws_data: any[][] = [
    ["Номенклатурный номер", "Наименование материала", "Единица измерения", "Норма расхода"],
  ]

  // Calculate max width for each column
  const maxWidths = ws_data[0].map((header) => header.length)

  recipes.forEach((recipe) => {
    const packagingMaterials = (recipe.packagingMaterials || [])
      .map((pm) => ({
        ...pm.packagingMaterial,
        lossPercentage: pm.expenditure?.lossPercentage,
        measure: pm.packagingMaterial
          ? measureLocales[pm.packagingMaterial.measure as keyof typeof measureLocales]
          : "",
      }))
      .filter((pm): pm is PackagingMaterialDto & { lossPercentage: number } => pm !== undefined)

    const recipeName = `Рецепт № ${recipe.name} (id: ${recipe.id})`

    // Add the recipe name spanning all columns
    ws_data.push([recipeName, null, null, null])

    // Check the width of the recipe name
    maxWidths[0] = Math.max(maxWidths[0], recipeName.length)

    // Add rows for each material
    packagingMaterials.forEach((material) => {
      const row = [material.article, material.name, material.measure, material.lossPercentage]
      ws_data.push(row)
      row.forEach((cell, index) => {
        const cellLength = cell != null ? cell.toString().length : 0
        maxWidths[index] = Math.max(maxWidths[index] || 0, cellLength)
      })
    })
  })

  // Create a worksheet
  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(ws_data)

  // Initialize the merges array
  ws["!merges"] = []
  let currentRowIndex = 1

  // Adding merge ranges after creating the worksheet
  recipes.forEach((recipe) => {
    const materialCount = recipe.packagingMaterials ? recipe.packagingMaterials.length : 0
    ws["!merges"]?.push({ s: { r: currentRowIndex, c: 0 }, e: { r: currentRowIndex, c: 3 } })
    currentRowIndex += 1 + materialCount // Skip over the recipe name row and its materials
  })

  // Adjust column widths
  ws["!cols"] = maxWidths.map((width) => ({ wch: width + 2 }))

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, sheetName)
}
