import { useMemo, useState } from "react"

import Search from "antd/es/input/Search"
import { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { Modal, TableProps } from "antd"
import {
  useAllRawMaterials,
  useCreateCopyRawMaterial,
} from "api/endpoints/materials-raw/materials-raw"
import { RawMaterialDto, RawMaterialForm } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseJournal } from "shared/BaseJournal"
import { defaultSortedInfo } from "shared/constants/sort"
import { exportTable } from "shared/utils/exportTable"
import { selectListResultOptions } from "shared/utils/selectListResult"

import { createTableColumns } from "./artifacts/createTableColumns"
import { RawMaterialsModal } from "./components/RawMaterialsModal"

export const RawMaterials = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [sortedInfo, setSortedInfo] = useState<SorterResult<RawMaterialDto>>(defaultSortedInfo)
  const [initialData, setInitialData] = useState<Partial<RawMaterialForm & { id: number }>>({})

  const {
    data: rawMaterials,
    isFetching: isMaterialsFetching,
    queryKey: rawMaterialsQueryKey,
  } = useAllRawMaterials(
    {},
    {
      query: { select: selectListResultOptions },
    },
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const materialsData = useMemo(() => {
    if (!rawMaterials) return []
    return rawMaterials
      .filter((material) => {
        return searchValue
          ? material.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1
          : true
      })
      .sort()
  }, [rawMaterials, searchValue])

  const handleChange: TableProps<RawMaterialDto>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<RawMaterialDto>)
  }

  const handleExport = () => {
    if (!rawMaterials) return

    const flattenData: Record<string, string | number | undefined>[] = rawMaterials.map((item) => {
      return {
        id: item.id,
        Название: item.name,
        "МДЖ %": item.fatPercentage,
        "МДБ %": item.proteinPercentage,
      }
    })

    exportTable({ data: flattenData, fileName: "Сырье" })
  }

  const onEditClick = (record: RawMaterialDto) => {
    setInitialData(record)
    setIsOpen(true)
  }

  const copyRawMaterial = useCreateCopyRawMaterial()

  const onCopyClick = async (id: number) => {
    Modal.confirm({
      title: "Вы точно хотите создать дубликат сырья?",
      okText: "Да",
      okType: "primary",
      cancelText: "Нет",
      icon: null,
      onOk() {
        copyRawMaterial
          .mutateAsync({ id })
          .then((res) => onEditClick(res.result))
          .then(() => queryClient.invalidateQueries({ queryKey: rawMaterialsQueryKey }))
          .catch(console.error)
      },
    })
  }

  const columns: ColumnsType<RawMaterialDto> = useMemo(() => {
    return createTableColumns({
      sortedInfo,
      isLoading: isMaterialsFetching || copyRawMaterial.isPending,
      onCopyClick,
      onEditClick,
    })
  }, [isMaterialsFetching, sortedInfo, copyRawMaterial.isPending])

  return (
    <BaseJournal
      title='Сырье'
      onAddClick={() => setIsOpen(true)}
      onExportClick={handleExport}
      data={rawMaterials ?? []}
      table={{
        loading: isMaterialsFetching || copyRawMaterial.isPending,
        columns,
        onChange: handleChange,
        dataSource: materialsData,
      }}
      filters={
        <>
          <Search onChange={onChange} placeholder='Поиск по именованию' />
        </>
      }
      modals={
        <RawMaterialsModal
          initialData={initialData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          clearInitialData={() => setInitialData({})}
          id={initialData?.id}
        />
      }
    />
  )
}
