import { Col, Row, Space, Typography } from "antd"

export const ExpenditureSum = ({
  amount,
  lossPercentage,
}: {
  amount: number
  lossPercentage: number
}) => {
  return (
    <Row>
      <Col span={6} />
      <Col span={18}>
        <Space
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 20px",
            textAlign: "center",
            marginTop: -10,
            marginBottom: 24,
            paddingBottom: 12,
            borderBottom: "1px solid #f0f0f0",
          }}
          align='end'
        >
          <Typography.Text style={{ display: "block", width: 400, textAlign: "start" }} strong>
            Итого:
          </Typography.Text>

          <Typography.Text>{Number(amount.toFixed(5))} кг</Typography.Text>
          <Typography.Text>{Number(lossPercentage.toFixed(5))} кг</Typography.Text>
        </Space>
      </Col>
    </Row>
  )
}
