import { useEffect, useMemo, useState } from "react"

import { Modal, Form, Select, Switch, Card, Space, InputNumber } from "antd"
import { useAllBasicMaterials } from "api/endpoints/materials-basic/materials-basic"
import { useAllPackagingMaterials } from "api/endpoints/materials-packaging/materials-packaging"
import { useAllMixes } from "api/endpoints/mixes/mixes"
import { useAllProducts } from "api/endpoints/products/products"
import { useCreateRecipe, useDeleteRecipe, useUpdateRecipe } from "api/endpoints/recipes/recipes"
import { RecipeForm } from "api/model"
import { ErrorType } from "api/mutator/custom-instance"
import { queryClient } from "app/queryClient"
import { FormList } from "pages/admin/Recipes/components/FormList"
import { BaseModalActions } from "shared/ui/BaseModalActions"
import { sleep } from "shared/utils/sleep"
import { requiredEmpty } from "shared/validators"

import { ConfirmDeleteModal } from "../../../../shared/ui/ConfirmDeleteModal"
import { useRecommendedNum } from "../useRecommendedNum"
import { ExpenditureSum } from "./ExpenditureSum"

const { Item } = Form

const labelCol = { span: 24 }

const validateMessages = {
  required: "поле обязательно для заполнения",
}

const makeOptions = (array: { id: number; name: string; measure?: string }[]) =>
  array.map(({ id, name, measure }) => ({ value: id, label: name, measure: measure ?? "KILOGRAM" }))

const filterOption = (input: string, option?: { label: string }) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

type RecipeUpdateError = ErrorType<{
  info?: { errors?: unknown[] }
}>

type RecipeModalProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  initialData?: Partial<RecipeForm & { id: number }>
  recipeId?: string
  clearInitialData: () => void
}

export function RecipeModal({
  isOpen,
  setIsOpen,
  recipeId,
  initialData,
  clearInitialData,
}: RecipeModalProps) {
  const [form] = Form.useForm()

  const packagingMaterialsWatcher: RecipeForm["packagingMaterials"] = Form.useWatch(
    "packagingMaterials",
    form,
  )
  const basicMaterialsWatcher: RecipeForm["basicMaterials"] = Form.useWatch("basicMaterials", form)
  const productIdWatcher: RecipeForm["productId"] = Form.useWatch("productId", form)
  const mixExpenditureWatcher: RecipeForm["mixExpenditure"] = Form.useWatch("mixExpenditure", form)

  const isCreateMode = !recipeId

  const { data: basicMaterialsData } = useAllBasicMaterials()
  const { data: packagingMaterialsData } = useAllPackagingMaterials()
  const { data: productsData } = useAllProducts()
  const { data: mixesData } = useAllMixes()

  const {
    isNumInvalid,
    lastRequestedNum,
    recommendedNum,
    setIsNumInvalid,
    setLastRequestedNum,
    shouldReqRecommendedNum,
    setShouldReqRecommendedNum,
  } = useRecommendedNum({
    isCreateMode,
    isModalOpen: isOpen,
    productId: productIdWatcher,
    setRecommendedNum: () => form.setFieldValue("num", recommendedNum),
  })

  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)

  const basicMaterials = basicMaterialsData?.result ?? []
  const packagingMaterials = packagingMaterialsData?.result ?? []
  const products = productsData?.result ?? []
  const mixes = mixesData?.result ?? []

  const mixAndMaterialsSum = useMemo(() => {
    const basicMaterialsSum = (basicMaterialsWatcher as RecipeForm["basicMaterials"])
      ?.filter(Boolean)
      ?.reduce(
        (acc, el) => {
          const currentMaterialMeasure = basicMaterials.find(({ id }) => id === el?.id)?.measure

          const currentMaterialAmount = el.expenditure?.amount ?? 0
          const currentMaterialLossPercentage = el.expenditure?.lossPercentage ?? 0

          if (!currentMaterialMeasure || currentMaterialMeasure !== "KILOGRAM") return acc

          return {
            amount: acc.amount + currentMaterialAmount,
            lossPercentage: acc.lossPercentage + currentMaterialLossPercentage,
          }
        },
        { amount: 0, lossPercentage: 0 },
      )

    const mixExpenditureAmount = mixExpenditureWatcher?.amount ?? 0
    const mixExpenditureLossPercentage = mixExpenditureWatcher?.lossPercentage ?? 0

    return {
      amount: basicMaterialsSum?.amount + mixExpenditureAmount,
      lossPercentage: basicMaterialsSum?.lossPercentage + mixExpenditureLossPercentage,
    }
  }, [basicMaterialsWatcher, mixExpenditureWatcher])

  const packagingMaterialsOptions = makeOptions(packagingMaterials)
  const basicMaterialsOptions = makeOptions(basicMaterials)
  const mixesOptions = makeOptions(mixes)

  const productsOptions = products.map(({ id, name, fatPercentage }) => ({
    value: id,
    label: `${name} (${fatPercentage}%)`,
  }))

  const handleRequestError = async (error: RecipeUpdateError, values: RecipeForm) => {
    const errorResponse = error?.response?.data as unknown as {
      info: { errors: { message: string }[] }
    }

    if ("info" in errorResponse && errorResponse?.info?.errors?.length) {
      setLastRequestedNum(values.num)
      setIsNumInvalid(true)

      await sleep(100)

      form.validateFields(["num"])
    }
  }

  const deleteRecipeService = useDeleteRecipe()

  const deleteRecipe = () => {
    if (!recipeId) return
    deleteRecipeService.mutate(
      { id: Number(recipeId) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
          setIsOpenDeleteConfirmModal(false)
        },
      },
    )
  }

  const createRecipeService = useCreateRecipe()

  const createRecipe = (values: RecipeForm) => {
    createRecipeService.mutate(
      { data: values },
      {
        onSuccess: () => {
          setLastRequestedNum(undefined)
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
        onError: (error) => handleRequestError(error as unknown as RecipeUpdateError, values),
      },
    )
  }

  const updateRecipeService = useUpdateRecipe()

  const updateRecipe = (values: RecipeForm) => {
    updateRecipeService.mutate(
      { id: Number(recipeId), data: values },
      {
        onSuccess: () => {
          setLastRequestedNum(undefined)
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
        onError: (error) => handleRequestError(error as unknown as RecipeUpdateError, values),
      },
    )
  }

  const onFinish = (values: RecipeForm) => {
    if (!recipeId) {
      createRecipe(values)
    } else {
      updateRecipe(values)
    }
  }

  const beforeClose = () => {
    setShouldReqRecommendedNum(false)
    form.resetFields()
    clearInitialData()
  }

  useEffect(() => {
    if (isOpen) return
    beforeClose()
  }, [isOpen])

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [initialData])

  return (
    <Modal
      afterClose={beforeClose}
      destroyOnClose
      title={recipeId ? "Редактирование рецепта" : "Добавление рецепта"}
      centered
      open={isOpen}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      footer={false}
      width={1100}
    >
      <Form
        validateMessages={validateMessages}
        name='recipes'
        form={form}
        style={{ marginTop: 20 }}
        labelCol={{ span: 24 }}
        onFinish={onFinish}
      >
        <Item
          name='productId'
          labelCol={{ span: 6 }}
          label='Наименование ГП'
          required
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            filterOption={filterOption}
            placeholder='Выберите продукт'
            options={productsOptions}
            onSelect={() => {
              if (shouldReqRecommendedNum === false) setShouldReqRecommendedNum(true)
            }}
          />
        </Item>

        <Item
          name='num'
          labelCol={{ span: 6 }}
          label='Номер рецепта'
          validateTrigger='onChange'
          required
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                if (!isNumInvalid) return Promise.resolve()

                if (!value) return Promise.resolve()

                if (Number(value) === Number(recommendedNum)) {
                  return Promise.resolve()
                }

                if (Number(value) !== Number(lastRequestedNum)) {
                  return Promise.resolve()
                }

                return Promise.reject(
                  new Error(
                    `Номер "${value}" уже используется. Ближайший свободный номер: ${recommendedNum}`,
                  ),
                )
              },
            }),
          ]}
        >
          <InputNumber style={{ width: "100%" }} type='number' placeholder='Номер рецепта' />
        </Item>

        {/* TODO: возможно нужно будет вернуть */}
        {/* <FormList
          showSum
          name='rawMaterials'
          entityName='Сырье'
          title='Сырье'
          options={rawMaterialsOptions}
          values={rawMaterialsWatcher}
        /> */}

        <Item required label='Смесь' labelCol={{ span: 6 }}>
          <Card size='small'>
            <Space style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }} align='end'>
              <Item name='mixId' labelCol={labelCol} required rules={[{ required: true }]}>
                <Select
                  showSearch
                  filterOption={filterOption}
                  placeholder='Выберите смесь'
                  options={mixesOptions}
                  style={{ width: 400 }}
                />
              </Item>

              <Item
                required
                rules={[requiredEmpty]}
                label='Расход'
                labelCol={labelCol}
                name={["mixExpenditure", "amount"]}
              >
                <InputNumber
                  decimalSeparator=','
                  maxLength={8}
                  placeholder='Введите значение'
                  addonAfter='кг'
                />
              </Item>
              <Item
                label='Расход с учетом потерь'
                labelCol={labelCol}
                name={["mixExpenditure", "lossPercentage"]}
              >
                <InputNumber
                  decimalSeparator=','
                  maxLength={8}
                  placeholder='Введите значение'
                  addonAfter='кг'
                />
              </Item>
            </Space>
          </Card>
        </Item>

        <FormList
          name='basicMaterials'
          entityName='Материал'
          title='Основные материалы'
          options={basicMaterialsOptions}
          values={basicMaterialsWatcher}
        />

        <ExpenditureSum
          amount={mixAndMaterialsSum.amount}
          lossPercentage={mixAndMaterialsSum.lossPercentage}
        />

        <FormList
          name='packagingMaterials'
          entityName='Материал'
          title='Упаковочные материалы'
          options={packagingMaterialsOptions}
          values={packagingMaterialsWatcher}
        />

        <Item
          initialValue={false}
          colon
          labelCol={{ span: 6 }}
          name='active'
          label='Активен'
          valuePropName='checked'
        >
          <Switch />
        </Item>

        <BaseModalActions
          isDeleteVisible={Boolean(recipeId)}
          onDelete={() => setIsOpenDeleteConfirmModal(true)}
        />
      </Form>

      <ConfirmDeleteModal
        isOpen={isOpenDeleteConfirmModal}
        setIsOpen={setIsOpenDeleteConfirmModal}
        title='Вы уверены, что хотите удалить данный рецепт?'
        action={deleteRecipe}
      />
    </Modal>
  )
}
