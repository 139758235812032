import { ProductDto, RecipeDto } from "api/model"
import * as XLSX from "xlsx"

import { formatDateAndTime } from "../../shared/utils/dateTime"
import { createExcelTable } from "./utils/createFirstTable"
import {
  createFirstTableData,
  getProductsWithRecipesOptimized,
} from "./utils/prepareFirstTableData"
import { createPackagingMaterialsTableData } from "./utils/rawMaterialConsumptionRatesTable"

export const exportRecipes = ({
  recipes,
  products,
}: {
  recipes: RecipeDto[]
  products: ProductDto[]
}) => {
  const productsWithRecipes = getProductsWithRecipesOptimized(products, recipes)
  const wb = XLSX.utils.book_new()
  createPackagingMaterialsTableData(recipes, wb, "Упаковочные материалы")

  productsWithRecipes.forEach((product) => {
    const firstTableData = createFirstTableData(product)
    const sheetName = `${product.name}, ${product.fatPercentage}%`.slice(0, 31).replace(/\//g, "-")
    createExcelTable(firstTableData, wb, sheetName) // Populate and add the worksheet to the workbook
  })

  // Check if the workbook has sheets
  if (wb.SheetNames && wb.SheetNames.length > 0) {
    XLSX.writeFile(wb, `Рецепты ${formatDateAndTime(new Date())}.xlsx`)
  } else {
    console.error("No sheets to write to workbook")
  }
}
