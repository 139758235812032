import { ProductDto, RecipeDto } from "api/model"

type ProductDtoWithRecipes = ProductDto & { recipes: RecipeDto[] }

type PreparedTableRow = Record<string, string | number | undefined>

// const mixDefaultValues = {
//   milkPercentage: 3.4,
//   reversePercentage: 0.05,
//   creamPercentage: 35,
//   buttermilkPercentage: 0.4,
// }

// const mixDefaultLabels = {
//   milkPercentage: "Молоко натуральное",
//   reversePercentage: "Обрат",
//   creamPercentage: "Сливки натуральные",
//   buttermilkPercentage: "Пахта",
// }

export function getProductsWithRecipesOptimized(
  products: ProductDto[],
  recipes: RecipeDto[],
): ProductDtoWithRecipes[] {
  // Create a map of recipes keyed by product ID
  const recipeMap = recipes.reduce((map: Record<number, RecipeDto[]>, recipe) => {
    if (!map[recipe.product.id]) {
      map[recipe.product.id] = []
    }
    map[recipe.product.id].push(recipe)
    return map
  }, {})

  // Map over products and assign the corresponding recipes from the map
  return products.map((product) => ({
    ...product,
    recipes: recipeMap[product.id] || [],
  }))
}

const getRecipeKey = (recipe: RecipeDto) => `Рецепт № ${recipe.name} (${recipe.id})`

const getBasicMaterialsRows = (recipe: RecipeDto) => {
  const basicMaterials = recipe.basicMaterials || []
  const basicMaterialsRows: PreparedTableRow[] = []
  const recipeKey = getRecipeKey(recipe)

  for (const item of basicMaterials) {
    const { basicMaterial } = item
    if (!basicMaterial) break

    const { article, name } = basicMaterial
    basicMaterialsRows.push({
      "Номенклатурный номер": article,
      "Наименование сырья": name,
      "М.д.ж.,%": 0,
      [recipeKey]: item.expenditure?.lossPercentage,
    })
  }

  return basicMaterialsRows
}

// TODO: в разработке (пока не используется, уточняются данные)
// const getMixesRows = (recipe: RecipeDto) => {
//   const recipeKey = getRecipeKey(recipe)
//   return [
//     {
//       "Номенклатурный номер": "recipe.mix.article",
//       "Наименование сырья": mixDefaultLabels["milkPercentage"],
//       "М.д.ж.,%": mixDefaultValues["milkPercentage"],
//       // [recipeKey]: recipe.milk,
//     },
//     {
//       "Номенклатурный номер": "recipe.mix.article",
//       "Наименование сырья": mixDefaultLabels["buttermilkPercentage"],
//       "М.д.ж.,%": mixDefaultValues["buttermilkPercentage"],
//       // [recipeKey]: recipe.buttermilk,
//     },
//     {
//       "Номенклатурный номер": "recipe.mix.article",
//       "Наименование сырья": mixDefaultLabels["reversePercentage"],
//       "М.д.ж.,%": mixDefaultValues["reversePercentage"],
//       // [recipeKey]: recipe.reverse,
//     },
//     {
//       "Номенклатурный номер": "recipe.mix.article",
//       "Наименование сырья": mixDefaultLabels["creamPercentage"],
//       "М.д.ж.,%": mixDefaultValues["creamPercentage"],
//       // [recipeKey]: recipe.cream,
//     },
//   ]
// }

export const createFirstTableData = (product: ProductDtoWithRecipes) => {
  // const mixRows: PreparedTableRow[] = []
  const basicMaterialsRows: PreparedTableRow[] = []

  product.recipes.forEach((recipe) => {
    basicMaterialsRows.push(...getBasicMaterialsRows(recipe))
    // mixRows.push(...getMixesRows(recipe))
  })

  const rows = [...basicMaterialsRows]

  return rows
}
