import * as XLSX from "xlsx"

type PreparedTableRow = Record<string, string | number | undefined>

export const createExcelTable = (
  data: PreparedTableRow[],
  wb: XLSX.WorkBook,
  sheetName: string,
) => {
  const title = "Нормы расхода сырья в кг на производство 1000 кг продукта:"

  const colHeaderSet: Set<string> = new Set()
  data.forEach((row) => {
    Object.keys(row).forEach((key) => colHeaderSet.add(key))
  })
  const headersArray: string[] = Array.from(colHeaderSet)

  // Calculate sums for each column, skipping the first three columns
  const sums = headersArray.reduce((acc, header, index) => {
    if (index >= 3) {
      acc[header] = data.reduce((sum, row) => sum + (Number(row[header]) || 0), 0)
    }
    return acc
  }, {} as Record<string, number>)

  const ws = XLSX.utils.json_to_sheet([{}, {}, ...data, sums], {
    skipHeader: true,
  })

  // Dynamically determine the number of columns to span for the title
  ws["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: headersArray.length - 1 } }]

  // Set the title in the first row and merge cells for the title
  ws["A1"] = { v: title, t: "s", s: { font: { bold: true } } }

  // Set column widths
  headersArray.forEach((header, colIndex) => {
    const maxContentWidth = data.reduce(
      (maxWidth, row) => Math.max(maxWidth, row[header]?.toString().length || 0),
      header.length,
    )
    const colWidth = maxContentWidth + 2

    ws["!cols"] = ws["!cols"] || []
    ws["!cols"][colIndex] = { wch: colWidth }
  })

  // Setting headers in the second row
  headersArray.forEach((header, colIndex) => {
    const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: 1 })
    ws[cellRef] = { v: header, t: "s", s: { font: { bold: true } } }
  })

  // Set the summary row
  const sumRowIndex = data.length + 3 // Adjust the row index for the sums

  const cellRef = XLSX.utils.encode_cell({ c: 0, r: sumRowIndex - 1 })
  ws["!merges"].push({ s: { r: sumRowIndex - 1, c: 0 }, e: { r: sumRowIndex - 1, c: 2 } }) // Merge first 3 columns for the title
  ws[cellRef] = { v: "ИТОГО с учетом потерь:", t: "s", s: { font: { bold: true } } }

  // Insert sums into the summary row, starting from the 4th column
  // headersArray.forEach((header, colIndex) => {
  //   if (colIndex >= 3) {
  //     // Adjust index as needed
  //     const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: sumRowIndex - 1 })
  //     ws[cellRef] = { v: sums[header], t: "n" } // 'n' type for number
  //   }
  // })

  // Apply left alignment to each cell in the column, starting from the third row
  // data.forEach((row, rowIndex) => {
  //   headersArray.forEach((header, colIndex) => {
  //     const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 2 })
  //     ws[cellRef] = ws[cellRef] || {}
  //     ws[cellRef].v = row[header]
  //     ws[cellRef].t = "s"
  //     ws[cellRef].s = {
  //       alignment: {
  //         horizontal: "left",
  //       },
  //     }
  //   })
  // })

  XLSX.utils.book_append_sheet(wb, ws, sheetName)
}
